import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Cases from './views/customer/Cases';
import SubmitCase from './views/SubmitCase';
import EditCase from './views/EditCase';
import useBreadcrumbs from './services/buildfire/history/useBreadcrumbs';
import AdminCases from './views/admin/Cases';
import CustomerHome from './views/customer/Home';
import About from './views/About';
import Pricing from './views/Pricing';
import FAQ from './views/FAQ';
import Education from './views/Education';
import { useLoading } from './services/buildfire/loading/LoadingContext';
import Router, { useBadPracticeHistory } from './components/route/Router';
import CaseContext from './services/buildfire/rdb/CaseContext';
import { addDebugMessage } from './components/Debug';
import { useBuildfireSettings } from './context/SettingsContext';
import Login from './views/Login';
import Register from './views/Register';
import { useFirebase } from './services/firebase';
import { LoginState } from './services/firebase/FirebaseContext';
import NotFound from './views/NotFound';
import Account from './views/Account';
import ForgotPassword from './views/ForgotPassword';
import { useWebNotifications } from './hooks/useNotificationPermission';
import ChangePassword from './views/ChangePassword';
import { setUserInNeedOfMigrationStatus } from './services/firebase/database';
import { useBackButton, useUrlOpen } from './hooks/capacitor/app';
import usePushNotifications from './hooks/capacitor/pushNotifications/usePushNotifications';
import { User } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import useAppInfoLog from './hooks/useAppInfoLog';
import useRollbarPerson from './hooks/useRollbarPerson';
import useNotifCountClear from './hooks/useNotifCountClear';
import { StatusBar, Style } from '@capacitor/status-bar';
import DeleteAccount from './views/DeleteAccount';
import { isNotNativeApp } from './util/device';
import PaymentMethods from './views/customer/PaymentMethods';
import Alerts from './views/admin/Alerts';
import HospitalUsers from './views/hospital/Users';
import AdminUsers from './views/admin/Users';
import Invitation from './views/Invitation';
import UserCases from './views/UserCases';
import UserInvoices from './views/UserInvoices';
import Invoices from './views/admin/Invoices';
import UserManagement from './views/userManagement/UserManagement';
import Welcome from './views/Welcome';
import GroupRegistration from './views/admin/GroupRegistration';

// const ADMINS = ['test-admin@jeremyberglund.com'];
// const isAdmin = (email: string = '') => ADMINS.includes(email);

interface WidgetProps {
  currentUser?: User | null;
  isAdmin: boolean;
}

function useStatusBarStyle() {
  React.useEffect(() => {
    if (isNotNativeApp()) {
      return;
    }
    StatusBar.setStyle({ style: Style.Light });
  }, []);
}

function useIsLoaded() {
  const { isLoading, setIsLoading } = useLoading();
  React.useEffect(
    (async () => {
      await Promise.all([
        // document.fonts.load('1em Roboto'),
        // document.fonts.load('1em Material Icons'),
      ]);
      setIsLoading(false);
    }) as any,
    []
  );
  return isLoading;
}

function useEnableUserSelection() {
  React.useEffect(() => {
    document.body.classList.remove('noSelect');
  }, []);
}

function useAccountMigrated(currentUser?: User | null) {
  const { app } = useFirebase();
  React.useEffect(() => {
    if (currentUser && currentUser.email) {
      const database = getDatabase(app);
      setUserInNeedOfMigrationStatus(database, currentUser.email, false);
    }
  }, [currentUser?.email, app]);
}

const Widget: React.FC<WidgetProps> = ({ currentUser, isAdmin }) => {
  useBreadcrumbs();
  useWebNotifications();
  usePushNotifications();
  useUrlOpen();
  useBadPracticeHistory();
  // useEnableUserSelection();

  useAccountMigrated(currentUser);

  return (
    <Switch>
      <Route
        path="/admin/cases/:caseId?"
        render={() => (isAdmin ? <AdminCases /> : <Redirect to="/" />)}
      />
      <Route
        path="/admin/alerts"
        render={() => (isAdmin ? <Alerts /> : <Redirect to="/" />)}
      />
      <Route
        path="/admin/group-registration"
        render={() => (isAdmin ? <GroupRegistration /> : <Redirect to="/" />)}
      />
      <Route path="/admin/users/:userId/invoices/:invoiceId?">
        <UserInvoices />
      </Route>
      <Route path="/admin/users/:userId/cases/:caseId?">
        <UserCases />
      </Route>
      <Route
        path="/admin/users/:userId?"
        render={() => (isAdmin ? <AdminUsers /> : <Redirect to="/" />)}
      />
      <Route
        path="/admin/invoices/:invoiceId?"
        render={() => (isAdmin ? <Invoices /> : <Redirect to="/" />)}
      />

      {/* Customer routes */}
      <Route path="/cases/create">
        <SubmitCase />
      </Route>
      <Route path="/cases/:caseId/edit">
        <EditCase />
      </Route>
      <Route
        path="/cases/:caseId?"
        render={() => (isAdmin ? <Redirect to="/admin/cases" /> : <Cases />)}
      />
      <Route path="/users/:userId/invoices/:invoiceId?">
        <UserInvoices />
      </Route>
      <Route path="/users/:userId/cases/:caseId?">
        <UserCases />
      </Route>
      <Route
        path="/users/:userId?"
        render={() =>
          isAdmin ? <Redirect to="/admin/users" /> : <HospitalUsers />
        }
      />
      <Route path="/invite">
        <Invitation />
      </Route>

      {/* General pages */}
      <Route path="/about">
        <About />
      </Route>
      <Route path="/pricing">
        <Pricing />
      </Route>
      <Route path="/faq">
        <FAQ />
      </Route>
      <Route path="/education">
        <Education />
      </Route>
      <Route path="/login">
        <Login />
      </Route>
      <Route path="/reset-password">
        <ForgotPassword />
      </Route>
      <Route path="/register">
        <Register />
      </Route>
      <Route path="/account">
        <Account />
      </Route>
      <Route path="/update-password">
        <ChangePassword />
      </Route>
      <Route path="/delete-account">
        <DeleteAccount />
      </Route>
      <Route path="/payment-methods">
        <PaymentMethods />
      </Route>
      <Route path="/user-management">
        <UserManagement />
      </Route>
      <Route path="/welcome">
        <Welcome />
      </Route>

      <Route
        path="/"
        exact
        render={() => {
          return isAdmin ? <Redirect to="/admin/cases" /> : <CustomerHome />;
        }}
      />
      <Route>
        {/* <CustomerHome /> */}
        <NotFound />
      </Route>
    </Switch>
  );
};

function useClearUserData(currentUser: User | null) {
  const { setCases } = React.useContext(CaseContext);
  React.useEffect(() => {
    if (currentUser) {
      setCases([]);
    }
  }, [currentUser?.uid]);
}

const WidgetContainer: React.FC<{}> = (props) => {
  const { currentUser, isAdmin, loginState } = useFirebase();
  const { settings, lastUpdated } = useBuildfireSettings();
  // Clear user data on switching user
  useClearUserData(currentUser);
  useBackButton();
  useAppInfoLog();
  useRollbarPerson();
  useNotifCountClear();
  useStatusBarStyle();

  const isLoading = useIsLoaded();

  React.useEffect(() => {
    addDebugMessage({
      content: ['SETTINGS', settings],
    });
  }, [settings]);

  if (!settings || loginState === LoginState.LOADING || isLoading) {
    return null;
  }

  return (
    <>
      <Router key={lastUpdated} basename={settings?.baseUrl || undefined}>
        <Widget currentUser={currentUser} isAdmin={isAdmin} />
      </Router>
    </>
  );
};

export default WidgetContainer;
