import React from 'react';
import * as Yup from 'yup';
import { FieldArray, Formik, FormikHelpers } from 'formik';
import Form from '../common/Form';
import { IonButton, IonCol, IonInput, IonRow } from '@ionic/react';
import classNames from 'classnames';
import { uppercaseFirstLetter } from '../../util/string';
import { ButtonRow } from '../../views/login/common/styledComponents';
import IconButton from '../../mdc/IconButton';
import Typography from '../common/Typography';
import styled from 'styled-components';

export interface GroupResgistrationFormValues {
  adminEmail: string;
  adminDisplayName: string;
  users: {
    email: string;
    displayName: string;
  }[];
}

interface GroupRegistrationFormProps {
  onSubmit: (
    values: GroupResgistrationFormValues,
    helpers: FormikHelpers<GroupResgistrationFormValues>
  ) => Promise<void>;
}

const StyledForm = styled(Form)`
  max-width: calc(100% - 68px);
`;

const Section = styled.div`
  margin: 16px 0;
`;

const displayNameSchema = Yup.string()
  .min(3, `Name is too short`)
  .max(120, `Name is too long`)
  .required(`Name is required`);
const emailSchema = Yup.string()
  .email('Must be a valid email')
  .required('Email is required');

const GroupRegistrationForm: React.FC<GroupRegistrationFormProps> = ({
  onSubmit,
}) => {
  return (
    <Formik
      initialValues={{
        adminEmail: '',
        adminDisplayName: '',
        users: [
          {
            email: '',
            displayName: '',
          },
        ],
      }}
      onSubmit={onSubmit}
      validateOnMount
      validationSchema={Yup.object({
        adminEmail: emailSchema,
        adminDisplayName: displayNameSchema,
        users: Yup.array().of(
          Yup.object({
            email: emailSchema,
            displayName: displayNameSchema,
          })
        ),
      })}
    >
      {({
        values,
        isSubmitting,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        submitForm,
      }) => (
        <StyledForm
          onSubmit={async (e) => {
            e.stopPropagation();
            e.preventDefault();
            await submitForm();
            console.log('submitted');
          }}
        >
          <Section>
            <IonRow>
              <IonCol>
                <Typography>Hospital Admin</Typography>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <IonInput
                  className={classNames({
                    ['ion-invalid']: errors.adminDisplayName,
                    ['ion-touched']: touched.adminDisplayName,
                  })}
                  fill="solid"
                  label="Name"
                  labelPlacement="floating"
                  type="text"
                  name="adminDisplayName"
                  required
                  value={values.adminDisplayName}
                  onIonInput={(e) =>
                    setFieldValue('adminDisplayName', e.detail.value)
                  }
                  onIonBlur={() => setFieldTouched('adminDisplayName', true)}
                  errorText={uppercaseFirstLetter(
                    errors.adminDisplayName as string
                  )}
                />
              </IonCol>
              <IonCol>
                <IonInput
                  className={classNames({
                    ['ion-invalid']: errors.adminEmail,
                    ['ion-touched']: touched.adminEmail,
                  })}
                  fill="solid"
                  label="Email"
                  labelPlacement="floating"
                  type="email"
                  name="adminEmail"
                  required
                  inputmode="email"
                  value={values.adminEmail}
                  onIonInput={(e) =>
                    setFieldValue('adminEmail', e.detail.value)
                  }
                  onIonBlur={() => setFieldTouched('adminEmail', true)}
                  errorText={uppercaseFirstLetter(errors.adminEmail as string)}
                />
              </IonCol>
            </IonRow>
          </Section>

          <Section>
            <IonRow>
              <IonCol>
                <Typography>Users</Typography>
              </IonCol>
            </IonRow>
            <FieldArray name="users">
              {({ push, remove, form }) => (
                <>
                  {form.values.users.map(
                    (user: { email: string; displayName: string }, index) => (
                      <IonRow
                        style={{ marginRight: index > 0 ? '-68px' : undefined }}
                      >
                        <IonCol>
                          <IonInput
                            className={classNames({
                              ['ion-invalid']: (errors.users?.[index] as any)
                                ?.displayName,
                              ['ion-touched']: (touched.users?.[index] as any)
                                ?.displayName,
                            })}
                            fill="solid"
                            label="Name"
                            labelPlacement="floating"
                            type="text"
                            name={`users.${index}.displayName`}
                            required
                            value={user.displayName}
                            onIonInput={(e) =>
                              setFieldValue(
                                `users.${index}.displayName`,
                                e.detail.value
                              )
                            }
                            onIonBlur={() =>
                              setFieldTouched(
                                `users.${index}.displayName`,
                                true
                              )
                            }
                            errorText={uppercaseFirstLetter(
                              (errors.users?.[index] as any)
                                ?.displayName as string
                            )}
                          />
                        </IonCol>
                        <IonCol>
                          <IonInput
                            className={classNames({
                              ['ion-invalid']: (errors.users?.[index] as any)
                                ?.email,
                              ['ion-touched']: (touched.users?.[index] as any)
                                ?.email,
                            })}
                            fill="solid"
                            label="Email"
                            labelPlacement="floating"
                            type="text"
                            name={`users.${index}.email`}
                            required
                            value={user.email}
                            onIonInput={(e) =>
                              setFieldValue(
                                `users.${index}.email`,
                                e.detail.value
                              )
                            }
                            onIonBlur={() =>
                              setFieldTouched(`users.${index}.email`, true)
                            }
                            errorText={uppercaseFirstLetter(
                              (errors.users?.[index] as any)?.email as string
                            )}
                          />
                        </IonCol>
                        {index > 0 && (
                          <IonCol size="auto">
                            <IconButton
                              tabIndex={-1}
                              onClick={() => remove(index)}
                              style={{ marginLeft: '10px' }}
                              icon="close"
                              type="button"
                            />
                          </IonCol>
                        )}
                      </IonRow>
                    )
                  )}
                  <ButtonRow>
                    <IonButton
                      fill="clear"
                      expand="block"
                      style={{ width: '100%' }}
                      onClick={() => {
                        push({ email: '', displayName: '' });
                      }}
                    >
                      + Add User
                    </IonButton>
                  </ButtonRow>
                </>
              )}
            </FieldArray>
          </Section>

          <ButtonRow>
            <IonButton
              type="submit"
              disabled={isSubmitting}
              expand="block"
              style={{ width: '100%' }}
            >
              Register Accounts
            </IonButton>
          </ButtonRow>
        </StyledForm>
      )}
    </Formik>
  );
};

export default GroupRegistrationForm;
