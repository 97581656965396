import { IonContent, IonHeader, IonPage, useIonToast } from '@ionic/react';
import React from 'react';
import Toolbar from '../../components/common/Toolbar';
import GroupRegistrationForm, {
  GroupResgistrationFormValues,
} from '../../components/forms/GroupRegistrationForm';
import styled from 'styled-components';
import { groupRegistration } from '../../api/cloudFunctions';
import { useFirebase } from '../../services/firebase';
import { getFunctions } from 'firebase/functions';
import { warning } from 'ionicons/icons';
import Typography from '../../components/common/Typography';
import { useDialog } from '../../context/DialogContext';
import { FormikHelpers } from 'formik';

interface GroupRegistrationProps {}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 80px 0 42px 0;
  padding: 0 16px;
`;

const ContentContainer = styled.div`
  max-width: 100%;
  width: 400px;
  margin: 48px 0;
`;

const GroupRegistration: React.FC<GroupRegistrationProps> = (props) => {
  const { app } = useFirebase();
  const [present] = useIonToast();
  const { openDialogPromise } = useDialog();

  const createUsers = async (
    values: GroupResgistrationFormValues,
    helpers: FormikHelpers<GroupResgistrationFormValues>
  ) => {
    console.log(values);
    try {
      const fns = getFunctions(app);
      await groupRegistration(fns, values);
      helpers.resetForm();
      helpers.setFieldTouched('adminEmail', false, false);
      helpers.setFieldTouched('adminDisplayName', false, false);
      helpers.setFieldTouched('users', false, false);
      present('Users successfully created', 4000);
    } catch (e) {
      console.error(e);
      present({
        message: 'Error creating users',
        icon: warning,
        color: 'danger',
        duration: 6000,
      });
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <Toolbar title="Group Registration" />
      </IonHeader>
      <IonContent>
        <Container>
          <ContentContainer>
            <Typography as="h1" variant="primary">
              Group Registration
            </Typography>

            <Typography as="p" variant="body" margin="16px 0">
              Use this form to register a group of users under a hospital
              account. Each user will receive an email with a link to finish
              creating their account.
            </Typography>

            <GroupRegistrationForm
              onSubmit={async (values, helpers) => {
                openDialogPromise(
                  `Are you sure you want to create these users under hospital ${values.adminEmail}?`
                )
                  .then(() => createUsers(values, helpers))
                  .catch(() => console.log('Cancelled'));
              }}
            />
          </ContentContainer>
        </Container>
      </IonContent>
    </IonPage>
  );
};

export default GroupRegistration;
